import './App.css';
import Section from "./components/Section";
import {useState} from "react";

function App() {

    const sections = [
        {
            title: "LASER LEAK DETECTION",
            description:"Discover Rosemount Aerosol Leak Detection Systems",
            address: "http://laserdetection.eu/",
            addressTxt: "laserdetection.eu",
            background: "laserdetection.jpg",
            backgroundPosition: "20% 90%",
            overlayColor: "#000000",
            descriptionColor: "#FFFFFF",
            logo: "engineering.png",
            disabled: false
        },
        {
            title: "GAS FILTERS",
            description:"Providing Destenching Columns for Your company",
            addressTxt: "gasfilters.eu",
            address: "http://gasfilters.eu/",
            background: "gasfilters.jpg",
            backgroundPosition: "0% 50%",
            overlayColor: "#00a98f",
            descriptionColor: "#000000",
            logo: "haj.png",
            disabled: false
        }
    ];

    const [clickedClass, setClickedClass] = useState('notClicked');

    const onClickHandler = () => {
        setClickedClass('clicked');
    }

  return (
    <div className={`ElprimeroLanding ${clickedClass}`}>
        <div className="loading"><h3>Trwa ładowanie strony...</h3></div>
        <div className={`main-container`}>
            <div className="site-title">
                <img src="./engineering.png" alt="elprimero.eu"/>
            </div>
          <div className="main-window">
              {
                  sections.map( (sekcja) => (
                      <Section title={sekcja.title} onClickHandler={onClickHandler} description={sekcja.description} address={sekcja.address}  addressTxt={sekcja.addressTxt} descriptionColor={sekcja.descriptionColor} logo={sekcja.logo} background={sekcja.background} backgroundPosition={sekcja.backgroundPosition} overlayColor={sekcja.overlayColor} disabled={sekcja.disabled}/>))
              }
          </div>
            <div className="address">
                <p style={{textAlign: "center"}}>
                    <b>ELPRIMERO SP. Z O.O.</b><br/>
                    ul. Opolska 22/A2.17<br/>
                    40-084 Katowice<br/>
                    POLAND<br/><br/>
                </p>
                <p style={{textAlign: "center", marginLeft: "20px",  marginRight: "20px"}}>
                    phone: +48 (32) 352 04 24<br/>
                    mobile: +48 601 424 429<br/>
                    e-mail: pro@elprimero.eu<br/><br/>
                    </p>

                <p style={{textAlign: "center"}}>
                    NIP: 634-29-39-568<br/>
                    REGON: 381674393<br/>
                </p>
            </div>

        </div>
    </div>
  );
}

export default App;
